<!--
Copyright 2018 Quinn Michaels
This file is part of indra.design

indra.design is free software:
you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Foobar is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Foobar.  If not, see <https://www.gnu.org/licenses/>.
 -->

<template>
  <section class="share">
    <article class="share-icons">
      <a :class="{'share-icon': true, [share.name]: true}" :href="shareLink(share.link)" v-for="share in sharing" :target="share.name"><img :src="share.icon" alt=""></a>
    </article>
  </section>
</template>

<script>
// template javascript

export default {
  name: 'IndraShare',
  components: {},
  computed: {
    title() {
      return this.$store.getters['share/title']
    },
    description() {
      return this.$store.getters['share/description']
    },
    sharing() {
      return this.$store.getters['share/sharing']
    }
  },
  methods: {
    shareLink(link) {
      return `${link}${encodeURI(window.location.href)}`
    }
  },
  created() {}
}
</script>

<style lang="stylus" scoped>
  // custom template styles
  @require '../../styles/vars'
  $icon-size = 128px
  .share
    &-icons
      display: flex
      justify-content: center
    &-icon
      border-radius: 50%
      box-shadow: 0 0 9px $colors.charcoal
      width: $icon-size
      height: $icon-size
      margin: .5rem


</style>
