// Copyright 2018 Quinn Michaels
// This file is part of Indra VueJs Gui Boilerplate
//

// Indra VueJs Gui Boilerplate is free software:
// you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// Foobar is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with Foobar.  If not, see <https://www.gnu.org/licenses/>.

const config = require('./config.json');

import Vue from 'vue'

import VueTouch from 'vue-touch'
import VueSmoothScroll from 'vue-smooth-scroll'
import VueCookie from 'vue-cookie';
import VueRouter from 'vue-router'

import App from './App.vue'
import IndraMain from './components/main/index.vue'
import IndraList from './components/list/index.vue'
import IndraView from './components/view/index.vue'

const mainComponents = {IndraMain,IndraList,IndraView}

// build final routes array from json data
const routes = []
config.routes.forEach(route => {
  const {path, name, component, meta} = route
  routes.push({
    path,
    name,
    component: mainComponents[component],
    meta,
  });
})

import store from './store'

Vue.use(VueTouch)
Vue.use(VueSmoothScroll)
Vue.use(VueCookie)
Vue.use(VueRouter)

const router = new VueRouter({routes});
// handle route change for title and meta tags

router.beforeEach((to,from,next) => {
  const nearestTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.tags);
  const prevNearestMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.tags);
  if(nearestTitle) document.title = nearestTitle.meta.title;
  Array.from(document.querySelectorAll('[data-indra]')).map(el => el.parentNode.removeChild(el));
  if(!nearestMeta) return next();
  nearestMeta.meta.tags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-indra', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));
  next();
});

// global components
import IndraShare from './components/share/index.vue';
Vue.component('IndraShare', IndraShare);

function init() {
  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
    created() {}
  })
}
init()
