<!--
Copyright 2018 Quinn Michaels

This file is part of Indra VueJs Gui Boilerplate

Indra VueJs Gui Boilerplate is free software:
you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Foobar is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Foobar.  If not, see <https://www.gnu.org/licenses/>.
 -->

<template>
  <main class="main">
    <IndraShare></IndraShare>
    <IndraList></IndraList>
  </main>
</template>

<script>
// template javascript
import IndraShare from '../share/index.vue';
import IndraList from '../list/index.vue';

export default {
  name: 'IndraMain',
  components: {
    IndraShare,
    IndraList,
  },
  computed: {},
  methods: {},
  created() {}
}
</script>

<style lang="stylus" scoped>
// custom template styles
  @require '../../styles/vars'
  .main
    transition: $transition
    padding: 1rem
    > section
      transition: $transition
      margin-bottom: 5rem


    .hero-image, .hero-content > h1
      display: none

    @media screen and (min-width: 700px)
      .hero-image, .hero-content > h1
        display: inherit
</style>
