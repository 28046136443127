<!--
Copyright 2018 Quinn Michaels
This file is part of indra.design

indra.design is free software:
you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Foobar is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Foobar.  If not, see <https://www.gnu.org/licenses/>.
 -->

<template>
  <section class="blog-view" id="blog_view">
    <IndraShare></IndraShare>
    <button class="btn btn-warning blog-view-close" @click="topicClose"><i class="icn-arrow-left"></i></button>
    <div class="blog-view-container">
      <h1 v-html="topic.title"></h1>
      <div class="blog-view-author">
        by <span v-html="username(topic.posts[0].user)"></span>
      </div>
      <div class="blog-view-tags" v-if="topic.tags">
        tags <span v-for="tag in topic.tags" v-text="tag.value"></span>
      </div>
      <article class="blog-view-content" v-html="cleanHTML(topic.posts[0].content)"></article>
      <IndraShare></IndraShare>
    </div>
  </section>
</template>

<script>
// template javascript

export default {
  name: 'IndraView',
  components: {},
  computed: {
    topic() {
      return this.$store.getters['view/topic']
    },
  },
  methods: {
    topicClose() {
      return this.$router.go(-1);
    },
    cleanHTML(html) {
      return html.replace(/\/assets\/uploads\//g, 'https://rahula.club/assets/uploads/');
    },
    username(user) {
      return `<a target="rahula" href="https://rahula.club/user/${user.userslug}">${user.username}</a>`
    }
  },
  created() {
    this.$store.dispatch('view/topic', this.$route.params.id);
  }
}
</script>

<style lang="stylus">
  // custom template styles
  @require '../../styles/vars'
  .blog
    &-view
      &-container
        max-width: 900px
        margin: auto
        border-left: 3px solid $colors.blue
        padding: 1px 1rem

        .share
          border-top: 3px dotted $colors.fire
          padding-top: 2rem
          margin-top: 2rem

      &-close
        $button-size = 4rem
        transition: $transition
        font-size: ($button-size / 2)
        position: fixed
        top: .5rem
        left: .5rem
        line-height: 1
        border-radius: $button-size
        width: $button-size
        height: $button-size

      &-tags
        padding: .5rem
        font-size: 1.5rem

        span
          display: inline-block
          padding: 0 1rem
          background-color: $colors.yellow
          margin: 0 .1rem
          color: darken($colors.yellow, 75%)
          border-radius: .5rem

      &-author
        padding: .5rem
        font-size: 2rem
      &-content
        img
          max-width: 100% !important
</style>
