'use strict';
// Copyright 2018 Quinn Michaels
// This file is part of indra.design
//
// indra.design is free software:
// you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// Foobar is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with Foobar.  If not, see <https://www.gnu.org/licenses/>.

// THIS IS THE STORE TEMPLATE MAKE SURE TO ADDE IT TO THE ./store/index.js file
const axios = require('axios');
const callView = axios.create({
  baseURL: 'https://rahula.club/api/',
});


const view = {
  namespaced: true,
  state: {
    topic: false,
  },
  getters: {
    topic(state) {
      return state.topic;
    },
  },
  mutations: {
    topic(state, data) {
      state.topic = data;
    },
  },
  actions: {
    topic({commit}, data) {
      callView('topic/' + data).then(result => {
        const {data} = result;
        console.log('call');
        commit('topic', data);
      }).catch(console.error);
    }
  },
}

export { view }
