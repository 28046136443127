'use strict';
// Copyright 2018 Quinn Michaels
// This file is part of indra.design
//
// indra.design is free software:
// you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// Foobar is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with Foobar.  If not, see <https://www.gnu.org/licenses/>.

// THIS IS THE STORE TEMPLATE MAKE SURE TO ADDE IT TO THE ./store/index.js file
const axios = require('axios');
const callList = axios.create({
  baseURL: 'https://rahula.club/api/',
});


const list = {
  namespaced: true,
  state: {
    viewing: false,
    title: '<i class="icn icn-pencil"></i> Blog',
    description: 'Recent blogs from indra.design delivered by rahula.club',
    topics: [],
  },
  getters: {
    title(state) {
      return state.title;
    },
    description(state) {
      return state.description;
    },
    topics(state) {
      return state.topics;
    },
    viewing(state) {
      return state.viewing;
    }
  },
  mutations: {
    title(state, data) {
      state.title = data;
    },
    description(state, data) {
      state.description = data;
    },
    topics(state, data) {
      state.topics = data;
    },
    viewing(state, data) {
      state.viewing = data;
    },
  },
  actions: {
    title({commit}, data) {
      commit('title', data);
    },
    description({commit}, data) {
      commit('description', data);
    },
    topics({commit}, data) {
      callList('category/40/tyler-team').then(result => {
        const { topics } = result.data;
        commit('topics', topics)
      }).catch(console.error)
    },
    open({commit}, data) {
      callList('topic/' + data).then(result => {
        const {data} = result;
        commit('viewing', data);
      }).catch(console.error)
    },
    close({commit}) {
      commit('close');
    }
  },
}

export { list }
