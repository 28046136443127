<!--
// Copyright 2018 Quinn Michaels
// This file is part of Indra VueJs Gui Boilerplate
//
// Indra VueJs Gui Boilerplate is free software:
// you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// Foobar is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with Foobar.  If not, see <https://www.gnu.org/licenses/>.
 -->
<template>
  <section class="error">
    <div class="error-icon"><i class="icn icn-poop"></i></div>
    <h4>Error</h4>
    <div class="error-detail">
      <p><pre><code>{{JSON.stringify(error, null, 2)}}</code></pre></p>
    </div>
  </section>
</template>

<script>

export default {
  name: 'IndraError',
  computed: {
    error() {
      return this.$store.getters['error/error']
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    }
  },
}
</script>

<style lang="stylus" scoped>
  @require '../../styles/vars'
  .error
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    height: 100%
    width: 100%
    background: $colors.fire
    z-index: 9999
    display: flex
    justify-content: center
    align-content: center
    flex-flow: column nowrap
    align-items: center

    &-icon
      font-size: 10rem
      line-height: 1
</style>
