"use strict";
// Copyright 2018 Quinn Michaels
// This file is part of Indra VueJs Gui Boilerplate
//
// Indra VueJs Gui Boilerplate is free software:
// you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// Foobar is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with Foobar.  If not, see <https://www.gnu.org/licenses/>.

const error = {
  namespaced: true,
  state: {
    open: false,
    error: false,
  },
  getters: {
    open(state) {
      return state.open;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    toggle(state, data) {
      state.open = !state.open;
    },
    error(state, data) {
      if (data.display) state.open = true;
      state.error = data;
    },
  },
  actions: {
    toggle({commit}, data) {
      commit('toggle', data);
    },
    error({commit}, data) {
      commit('error', data);
    }
  },
};

export { error }
