<!--
Copyright 2018 Quinn Michaels
This file is part of indra.design

indra.design is free software:
you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

Foobar is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with Foobar.  If not, see <https://www.gnu.org/licenses/>.
 -->

<template>
  <section class="blog-list" id="blog_list">
    <article class="blog-list-topics" v-for="topic in topics">
      <h4><router-link :to="{name:'blogView', params: { id: topic.tid, title: topic.slug.split('/')[1] }}" v-html="topic.title"></router-link></h4>
      <caption>by {{topic.user.username}}</caption>
      <div class="blog-list-topics-tags"><span v-for="tag in topic.tags">{{tag.value}}</span></div>
    </article>
  </section>
</template>

<script>
// template javascript

export default {
  name: 'IndraList',
  components: {},
  computed: {
    topics() {
      return this.$store.getters['list/topics']
    },
  },
  methods: {},
  created() {
    this.$store.dispatch('list/topics');
  }
}
</script>

<style lang="stylus" scoped>
  // custom template styles
  @require '../../styles/vars'
  .blog-list
    &-topics
      padding: 1rem
      margin: .5rem
      border: 1px solid $colors.blue
      border-radius: .5rem
      h4
        padding: 0 1rem
        margin: 0
        a
          color: $colors.blue
          text-decoration: none

      &-tags
        span
          display: inline-block
          background: $colors.orange-lt
          margin: .1rem
          padding: .5rem 1rem
          border-radius: .5rem
          color: $colors.white
          font-weight: bold
          text-shadow: 0 2px 2px darken($colors.orange-lt, 30%)
</style>
